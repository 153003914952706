<template>
  <div>
    <div class="page">
      <div
        v-if="getModuleConfig('confirm_page.form_title')"
        class="page__title"
      >
        {{ getModuleConfig("confirm_page.form_title") }}
      </div>
      <div v-if="getModuleConfig('confirm_page.form_desc')" class="page__desc">
        {{ getModuleConfig("confirm_page.form_desc") }}
      </div>
      <div v-if="isNewWorker">
        <!-- Partner City -->
        <div class="field">
          <div class="d-flex">
            <div class="page__title text-left field--required">區域</div>
          </div>
          <div class="field__select-group">
            <SharedSelect
              v-model="form.partner_city"
              :options="partner_city_options"
              :hasBorder="false"
              :disabled="isBound"
              placeholder="請先選擇區域。"
            />
          </div>
        </div>
        <!-- Partner -->
        <div class="field">
          <div class="d-flex align-items-center page__title">
            <div class="text-left col-6 field--required">服務醫院</div>
            <div class="col-6 d-flex">
              <input class="field__input" type="text" v-model="keyword" placeholder="可輸入醫院名稱搜尋" /><i class="fa fa-search"></i>
            </div>
          </div>
          <div v-if="notFound" class="error-msg text-right">查無資料</div>
          <div class="d-flex row  align-items-center">
            <div class="col-3">
              <input
                type="radio"
                v-model="form.create_partner"
                :value="false"
              />
              選擇醫院
            </div>
            <SharedSelect
              ref="selectPartnerElement"
              class="col-9"
              v-model="form.partner_id"
              :options="partner_options"
              :hasBorder="false"
              :disabled="isBound || !form.partner_city || form.create_partner || loading"
            >
            </SharedSelect>
          </div>
          <div class="d-flex row mt-3 align-items-center">
            <div class="col-3">
              <input type="radio" v-model="form.create_partner" :value="true" />
              其他
            </div>
            <input
              class="field__input col-9"
              v-model="form.partner_name"
              :hasBorder="false"
              :disabled="!form.create_partner"
            />
          </div>
          <div
            v-if="createPartnerError"
            class="form__error-msg"
          >
            輸入的資料有誤或未填寫，請確認
          </div>
        </div>
        <div
          v-for="(field, index) in fields.filter((x) => {
            return x.hidden == undefined || !x.hidden;
          })"
          :key="`field.${index}`"
          class="field"
        >
          <!-- select type -->
          <div v-if="field.type === 'select' && field.matched_key">
            <div>
              <div
                class="page__title text-left"
                :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</div>
              <div class="d-flex row align-items-center">
                <div class="col-3" v-if="field.can_create">
                  <input
                    type="radio"
                    v-model="field.is_selected"
                    :value="false"
                  />
                  選擇{{ field.title }}
                </div>
                <SharedSelect
                  :class="field.can_create ? 'col-9' : 'col-12'"
                  v-model="form[field.field_key]"
                  :options="field.options"
                  :hasBorder="false"
                  :placeholder="field.placeholder"
                  :disabled="field.disabled"
                />
                <small v-if="field.memo" class="field__desc">{{ field.memo }}</small>
              </div>
              <div
                v-if="v$.form[field.field_key].$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
              <div class="d-flex row mt-3 align-items-center" v-if="field.can_create">
                <div class="col-3">
                  <input
                    type="radio"
                    v-model="field.is_selected"
                    :value="true"
                  />
                  其他
                </div>
                <input
                  class="field__input col-9"
                  v-model="form[field.field_key]"
                  :hasBorder="false"
                  :disabled="!field.is_selected"
                />
              </div>
            </div>
          </div>
          <!-- select type -->
          <!-- text type -->
          <div v-else-if="field.type === 'text' && field.matched_key">
            <div class="d-flex">
              <div
                class="page__title text-left"
                :class="field.required === true ? 'field--required' : null"
              >
                {{ field.title }}
              </div>
            </div>
            <div class="field__select-group">
              <input
                class="field__input flex-fill"
                v-model="form[field.field_key]"
                :class="{ invalid: v$.form[field.field_key].$error }"
                :placeholder="field.placeholder"
                :disabled="field.disabled"
                @keypress="v$.$touch()"
              />
            </div>
            <small v-if="field.memo" class="field__desc">
              {{ field.memo }}
            </small>
            <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- Partner -->
        <div class="field">
          <div class="d-flex">
            <div class="page__title text-left">服務醫院</div>
          </div>
          <div class="field__select-group">
            <SharedSelect
              v-model="form.partner_id"
              :options="partner_options"
              :hasBorder="false"
            >
            </SharedSelect>
          </div>
        </div>
        <!-- Job Specialty -->
        <div class="field">
          <div class="d-flex">
            <div class="page__title text-left">服務科室</div>
          </div>
          <div class="field__select-group">
            <input
              class="field__input flex-fill"
              v-model="form.job_specialty"
              disabled
            />
          </div>
        </div>
        <!-- Staff -->
        <div class="field">
          <div class="d-flex">
            <div class="page__title text-left">負責業務</div>
          </div>
          <div class="field__select-group">
            <input
              class="field__input flex-fill"
              v-model="form.staff_name"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page__button s-space-y-4">
      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="handleSubmit"
      >
        {{ getModuleConfig("confirm_page.button_text") }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import workerMixin from "@/mixins/liff/worker";
import workerApi from "@/apis/liff/v2/worker";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import _ from "lodash";

export default {
  mixins: [workerMixin],
  components: {
    SharedButton,
    SharedSelect,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      partnerData: [],
      partner_id: null,
      isBound: false,
      keyword: "",
      form: this.setForm(),
      fields: [],
      partner_city_options: [],
      isNewWorker: true,
      partner_options: [],
      data: [],
      mappings: [],
      notFound: false,
      loading: false,
      createPartnerError: false,
    };
  },
  async created() {
    this.fields = this.fetchFields();
    await this.fetchPartners();
    let result = await this.checkRegistered();

    if (result === false) {
      return;
    }
  },
  mounted() {
    // this.fields = this.fetchFields();
  },
  validations() {
    let form = {
      partner_city: { required }
    };
    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      form[field.field_key] = field.required === true ? { required } : {};
    }

    return {
      form,
    };
  },
  watch: {
    "keyword"() {
      this.filterPartner();
    },
    "form.partner_id"(value) {
      this.setPartner(value);
      this.createPartnerError = false;
    },
    "form.partner_city"(value) {
      this.keyword = "";
      this.loading = true;
      this.partner_options = this.partnerData[value].map((partner) => {
        return {
          text: `${partner.name}`,
          value: partner.hashid,
        };
      });
      this.form.partner_id = null;
      this.loading = false;
    },
    "form.create_partner"(value) {
      if (value) {
        this.form.partner_id = null;
        this.keyword = "";
      }
      this.createPartnerError = false;
    },
    "form.partner_name"(value) {
      if (value) {
        this.createPartnerError = false;
      }
    }
  },
  methods: {
    filterPartner() {
      if (this.keyword) {
        // 先reset 再過濾
        this.partner_options = this.partnerData[this.form.partner_city].map(
          (partner) => {
            return {
              text: `${partner.name}`,
              value: partner.hashid,
            };
          }
        );
        this.partner_options = this.partner_options.filter((partner) => {
          return partner.text.includes(this.keyword);
        });
      } else {
        this.partner_options = this.partnerData[this.form.partner_city].map(
          (partner) => {
            return {
              text: `${partner.name}`,
              value: partner.hashid,
            };
          }
        );
      }
      if (this.partner_options.length === 0) {
        this.notFound = true;
      } else {
        this.notFound = false;
        this.form.partner_id = this.partner_options[0].value;
      }
    },
    setPartner(value) {
      if (!this.isNewWorker) {
        const partner = this.data.find((d) => {
          return d.hashid == value;
        });
        this.form.job_specialty = partner.worker.job_specialty;
        this.form.staff_name = partner.staff_name;
      }
    },
    fetchFields() {
      let fields = this.getModuleConfig("confirm_page.verify_field");
      fields = _.map(fields, (field) => {
        let field_key;
        if (field.matched_key) {
          field_key = field.matched_key;
        } else {
          field_key = field.type;
        }

        let is_selected = false;

        return {
          ...field,
          field_key,
          is_selected,
        };
      });

      return _.sortBy(fields, "order");
    },
    setForm() {
      //解決動態 validations 必須先定義 form 的 property
      var form = {};
      let fields = this.fetchFields();
      // Refill fields
      fields.forEach((field) => {
        //其他一般欄位
        form[field.field_key] = field.value;
        return;
      });
      form.create_partner = false;
      form.partner_city = null;
      form.partner_id = null;

      return { ...form };
    },
    async fetchPartners() {
      let payload = this.$route.query;

      const { data } = await workerApi.getPartners(payload);
      this.isNewWorker = data.data.is_new_worker;
      this.mappings = data.data.mappings;
      this.data = data.data.data;
      if (!this.isNewWorker) {
        this.partner_options = this.data.map((partner) => {
          return {
            text: partner.name,
            value: partner.hashid,
          };
        });
        if (this.data.length == 1) {
          this.form.partner_id = this.data[0].hashid;
          this.form.staff_name = this.data[0].staff_name;
          this.form.job_specialty = this.data[0].worker.job_specialty;
        }
      } else {
        this.partnerData = this.data.reduce((acc, obj) => {
          const key = obj.city;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        this.partner_city_options = Object.keys(this.partnerData).map(city => {
          return {
            text: city,
            value: city,
          };
        });

        this.fields = this.fields.map((field) => {
          if (field.provider === "api:dept_name") {
            field.options = this.mappings["dept_name"].map((jtm) => {
              return {
                text: jtm,
                value: jtm,
              };
            });
          }
          if (field.provider === "api:job_title") {
            field.options = this.mappings["job_title"].map((jtm) => {
              return {
                text: jtm,
                value: jtm,
              };
            });
          }
          if (field.provider === "api:job_specialty") {
            field.options = this.mappings["job_specialty"].map((jtm) => {
              return {
                text: jtm,
                value: jtm,
              };
            });
          }
          return field;
        });
      }
    },
    async handleSubmit() {
      if (this.isNewWorker) {
        // 如果新建partner但沒有名稱 或是 選沒有選擇parnter
        let validFlag = (this.form.create_partner && !this.form.partner_name) || (!this.form.create_partner && !this.form.partner_id)
        if (validFlag) {
          this.createPartnerError = true;
        }
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }
      }
      const url =
        process.env.VUE_APP_API_BASE_URL +
        "/" +
        this.$route.params.orgCode +
        "/liff/partner-worker/active";
      let payload = _.merge(this.form, this.$route.query);
      await axios
        .post(url, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.goSuccess(response.data.next);
            return;
          }
        })
        .catch((error) => {
          if (
            error.response.data.status === "failed" &&
            error.response.data.message
          ) {
            this.$swal("啟用失敗", error.response.data.message, "error");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
  &__button {
    margin-top: 20px;
  }
}

.error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
}
</style>
